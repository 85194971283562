<template>
  <div class="onboarding-layout">
    <div class="left-panel">
      <img
        class="logo"
        src="/img/logo.svg"
        alt="Logo Hello Asso"
      >
      <slot name="left" />
    </div>
    <div class="right-panel">
      <slot name="right" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.onboarding-layout {
  @include mediaQuery(1200) {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 100vw;
  }

  .left-panel {
    min-height: 100vh;
    background-color: white;

    @include mediaQuery(1200) {
      grid-column: 1 / 4;
      justify-content: center;
    }
    @include mediaQuery(1920) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .right-panel {
    display: none;

    @include mediaQuery(1200) {
      display: block;
      grid-column: 4 / 6;
      background-color: #f0effd;
    }
  }

  .logo {
    width: 36px;
    margin: 30px;

    @include mediaQuery(1920) {
      position: absolute;
      top: 0;
    }
  }
}
</style>
